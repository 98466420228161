import React from 'react'

const About = () => {
  return (
    <div>
         <div className="container-fluid bg-primary hero-header mb-5">
        <div class="container text-center">
            <h1 class="display-4 text-white mb-3 animated slideInDown">About Us</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center mb-0 animated slideInDown">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                    <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                </ol>
            </nav>
        </div>
    </div>
   
    <div className="container-fluid py-5">
    <div className="container">
        <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <div className="feature-item position-relative bg-primary text-center p-3">
                    <div className="border py-5 px-3">
                        <i className="fa fa-leaf fa-3x text-dark mb-4"></i>
                        <h5 className="text-white mb-0">100% Cotton Soft</h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="feature-item position-relative bg-primary text-center p-3">
                    <div className="border py-5 px-3">
                        <i className="fa fa-tint fa-3x text-dark mb-4"></i>
                        <h5 className="text-white mb-0">Super Absorbent</h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="feature-item position-relative bg-primary text-center p-3">
                    <div className="border py-5 px-3">
                        <i className="fa fa-check fa-3x text-dark mb-4"></i>
                        <h5 className="text-white mb-0">Hypoallergenic</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="container-fluid py-5">
    <div className="container">
        <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <img className="img-fluid animated pulse infinite" src="assets/img/DSC07366.jpg" alt="Sanitary Pad"/>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 className="text-primary mb-4">Comfort <span className="fw-light text-dark">With Every Step</span></h1>
                <p className="mb-4">Our sanitary pads are designed to keep you comfortable and protected throughout your day. Made with soft cotton and a super absorbent core, they're hypoallergenic and gentle on your skin.</p>
                <p className="mb-4">Feel confident and secure with our high-quality pads that offer exceptional protection during your period. Stay fresh and dry all day long.</p>
                <a className="btn btn-primary py-2 px-4" href="#">Shop Now</a>
            </div>
        </div>
    </div>
</div>

<div className="container-fluid testimonial bg-primary my-5 py-5">
    <div className="container text-white py-5">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth:'600px'}}>
            <h1 className="text-white mb-3">
                 <span className="fw-light text-dark">Our Vision</span>
            </h1>
            <p className="mb-5">
            At Aruheal, we aim for a future where menstrual hygiene is both eco-friendly and empowering. We want to lead in providing healthy products, protect the environment, and ensure everyone has access to safe and sustainable hygiene.
            </p>
        </div>
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth:'600px'}}>
            <h1 className="text-white mb-3">
                 <span className="fw-light text-dark">Our Mission</span>
            </h1>
            <p className="mb-5">
            Our mission is to create eco-friendly sanitary and maternity pads from banana stem pulp. We aim to improve women’s health, support local farmers, and provide jobs for women, all while protecting the environment.
            </p>
        </div>
       
    </div>
</div>

<div className="container-fluid py-5">
      <div className="container">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
          <h1 className="text-primary mb-5">
            <span className="fw-light text-dark">Interested in Becoming a Distributor?</span> We Would Love to Hear From You
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-7 wow fadeIn" data-wow-delay="0.1s">
            <p className="mb-4">
              Please fill out the form below with your details, and our team will get back to you as soon as possible. We appreciate your interest in partnering with us.
            </p>
            <div className="wow fadeIn" data-wow-delay="0.3s">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="name" placeholder="Your Name" />
                      <label htmlFor="name">Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="email" className="form-control" id="email" placeholder="Your Email Address" />
                      <label htmlFor="email">Email Address</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="tel" className="form-control" id="contactNo" placeholder="Your Contact Number" />
                      <label htmlFor="contactNo">Contact No</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="experience" placeholder="Previous Experience" />
                      <label htmlFor="experience">Previous Experience</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="referral" placeholder="How did you hear about us?" />
                      <label htmlFor="referral">How did you hear about us?</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100 py-3" type="submit">
                      Submit Form
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.5s">
          <img className="img-fluid animated pulse infinite" src="assets/img/hand-drawn-international-trade-with-truck_23-2149157911.avif" alt="Sanitary Pad"/>

          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default About