import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="container-fluid bg-white footer">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                   <Link to="/" className="d-inline-block mb-3">
                        <h1 className="text-primary">Aruheal</h1>
                    </Link>
                    <p className="mb-0">Providing premium quality sanitary pads for ultimate comfort and protection. Experience the best with Aruheal, your trusted partner in feminine hygiene.</p>
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <h5 className="mb-4">Get In Touch</h5>
                    <p><i className="fa fa-map-marker-alt me-3"></i>Om Niwas , Aadarsh nagar ,  Behind D - Mart , In front of Agasti apartment ,  Jalgaon, Pincode - 425001</p>
                    <p><i className="fa fa-phone-alt me-3"></i>+91 92099 39693</p>
                    <p><i className="fa fa-envelope me-3"></i>aruhealsolutions@gmail.com</p>
                    <div className="d-flex pt-2" style={{justifyContent:'center'}}>
                       <a className="btn btn-square btn-outline-primary me-1" href="#"><i className="fab fa-twitter"></i></a>
                       <a className="btn btn-square btn-outline-primary me-1" href="#"><i className="fab fa-facebook-f"></i></a>
                       <a className="btn btn-square btn-outline-primary me-1" href="#"><i className="fab fa-instagram"></i></a>
                       <a className="btn btn-square btn-outline-primary me-1" href="#"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <h5 className="mb-4">Our Products</h5>
                   <Link className="btn btn-link" to="/product">SanitaryPads</Link>
                   <Link className="btn btn-link" to="/product">Maternity Pads</Link>
                   <Link className="btn btn-link" to="/product">Panty liners</Link>
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <h5 className="mb-4">Quick Links</h5>
                   <Link className="btn btn-link" to="/about">About Us</Link>
                   <Link className="btn btn-link" to="/contact">Contact Us</Link>
                   <Link className="btn btn-link" to="/product">Product</Link>
                 
                </div>
            </div>
        </div>
        <div className="container wow fadeIn" data-wow-delay="0.1s">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy;<Link className="border-bottom" to="/">Aruheal</Link>, All Rights Reserved.
                        Designed By<a className="border-bottom" href="www.shreedigimarketing.in"> Shree Digital Marketing Agency</a>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <div className="footer-menu">
                           <Link to="/">Home</Link>
                           <Link to="/about">About</Link>
                           <Link to="/product">Product</Link>
                           <Link to="/contat">contact</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Footer;
