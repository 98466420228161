import React from 'react'

const Product = () => {
  return (
    <div>
          <div className="container-fluid bg-primary hero-header mb-5">
        <div className="container text-center">
            <h1 className="display-4 text-white mb-3 animated slideInDown">Products</h1>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
                    <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                    <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                    <li className="breadcrumb-item text-white active" aria-current="page">Products</li>
                </ol>
            </nav>
        </div>
    </div>
   
    <div className="container-fluid py-5">
    <div className="container">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
            <h1 className="text-primary mb-3"><span className="fw-light text-dark">Our Premium</span> Sanitary Pads</h1>
            <p className="mb-5">Discover our top-quality sanitary pads designed for maximum comfort and protection. Perfect for every day of your cycle.</p>
        </div>
        <div className="row g-4">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <div className="product-item text-center border h-100 p-4">
                    <img className="img-fluid mb-4" src="assets/img/pantyliner.jpg" alt="Panty Liner"/>
                    <div className="mb-2">
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small>(85)</small>
                    </div>
                    <a href="" className="h6 d-inline-block mb-2">Sanitary Napkins(8 Pads)</a>
                    <h5 className="text-primary mb-3">Light and Comfortable</h5>
                    <p>XL Size (320 mm long )</p>
                    <h5 class="text-primary mb-3">INR &#8377; 80/-</h5>
                    <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="product-item text-center border h-100 p-4">
                    <img className="img-fluid mb-4" src="assets/img/maternitypads.jpg" alt="Maternity Pad"/>
                    <div className="mb-2">
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small>(90)</small>
                    </div>
                    <a href="" className="h6 d-inline-block mb-2">Maternity Pads(5 pads)</a>
                    <h5 className="text-primary mb-3">Soft and Absorbent</h5>
                    <p>XXXL Size (40 &times;10 cm)</p>
                    <h5 class="text-primary mb-3">INR &#8377; 125/-</h5>
                    <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="product-item text-center border h-100 p-4">
                    <img className="img-fluid mb-4" src="assets/img/DSC07366.jpg" alt="Sanitary Pad"/>
                    <div className="mb-2">
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small>(120)</small>
                    </div>
                    <a href="" className="h6 d-inline-block mb-2">Sanitary Pads(30 Pads)</a>
                    <h5 className="text-primary mb-3">Comfort and Protection</h5>
                    <p>XXL Size (340 mm long)</p>
                    <h5 class="text-primary mb-3">INR &#8377; 600/-</h5>
                    <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
                </div>
            </div>
        </div>
    </div>
</div>
       
<div className="container-fluid py-5">
      <div className="container">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
          <h1 className="text-primary mb-5">
            <span className="fw-light text-dark">Interested in Becoming a Distributor?</span> We Would Love to Hear From You
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-7 wow fadeIn" data-wow-delay="0.1s">
            <p className="mb-4">
              Please fill out the form below with your details, and our team will get back to you as soon as possible. We appreciate your interest in partnering with us.
            </p>
            <div className="wow fadeIn" data-wow-delay="0.3s">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="name" placeholder="Your Name" />
                      <label htmlFor="name">Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="email" className="form-control" id="email" placeholder="Your Email Address" />
                      <label htmlFor="email">Email Address</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="tel" className="form-control" id="contactNo" placeholder="Your Contact Number" />
                      <label htmlFor="contactNo">Contact No</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="experience" placeholder="Previous Experience" />
                      <label htmlFor="experience">Previous Experience</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="referral" placeholder="How did you hear about us?" />
                      <label htmlFor="referral">How did you hear about us?</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100 py-3" type="submit">
                      Submit Form
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.5s">
          <img className="img-fluid animated pulse infinite" src="assets/img/hand-drawn-international-trade-with-truck_23-2149157911.avif" alt="Sanitary Pad"/>

          </div>
        </div>
      </div>
    </div>

    </div>
  )
}

export default Product