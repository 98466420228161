import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const handleNavLinkClick = () => {
    const navbarCollapse = document.getElementById('navbarCollapse');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  };

  return (
    <div className="container-fluid sticky-top">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <img className="navbar-brand logo-img" src='assets/img/aru-removebg-preview.png'>
          </img>
          <button
            type="button"
            className="navbar-toggler ms-auto me-0"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto">
              <Link to="/" className="nav-item nav-link" onClick={handleNavLinkClick}>Home</Link>
              <Link to="/about" className="nav-item nav-link" onClick={handleNavLinkClick}>About</Link>
              <Link to="/product" className="nav-item nav-link" onClick={handleNavLinkClick}>Products</Link>
              <Link to="/contact" className="nav-item nav-link" onClick={handleNavLinkClick}>Contact</Link>
            </div>
            <Link to="/product" className="btn btn-dark py-2 px-4 d-none d-lg-inline-block">Shop Now</Link>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
