import React from 'react'

const Home = () => {
   
  return (
    <div>
         <div className="container-fluid bg-primary hero-header mb-5">
        <div className="container">
            <div className="row g-5 align-items-center">
                <div className="col-lg-6 text-center text-lg-start">
                    <h3 className="fw-light text-white animated slideInRight">Banana stem pulp Sanitary Pads</h3>
                    <h1 className="display-4 text-white animated slideInRight"><span className="fw-light text-dark">Aruheal</span> For Sustainable Period Care</h1>
                    <p className="text-white mb-4 animated slideInRight">
                   we believe every woman deserves comfort and confidence. Our range of sanitary pads offers superior protection, reliability, and comfort for every flow. Designed to give you peace of mind, no matter the time of the month.</p>
                    <a href="/product" className="btn btn-dark py-2 px-4 me-3 animated slideInRight">Shop Now</a>
                    <a href="/contact" className="btn btn-outline-dark py-2 px-4 animated slideInRight">Contact Us</a>
                </div>
                <div className="col-lg-6">
                    <img className="img-fluid animated pulse infinite" src="assets/img/DSC07344-removebg-preview.png" alt=""/>
                </div>
            </div>
        </div>
    </div>
   
    <div className="container-fluid py-5">
    <div className="container">
        <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <div className="feature-item position-relative bg-primary text-center p-3">
                    <div className="border py-5 px-3">
                        <i className="fa fa-leaf fa-3x text-dark mb-4"></i>
                        <h5 className="text-white mb-0">Eco-Friendly Material</h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="feature-item position-relative bg-primary text-center p-3">
                    <div className="border py-5 px-3">
                    <i className="fa fa-tint fa-3x text-dark mb-4"></i>
                    <h5 className="text-white mb-0">Super Absorbent Core</h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="feature-item position-relative bg-primary text-center p-3">
                    <div className="border py-5 px-3">
                    <i className="fa fa-check fa-3x text-dark mb-4"></i>
                    <h5 className="text-white mb-0">Gentle & Hypoallergic</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="container-fluid py-5">
    <div className="container">
        <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <img className="img-fluid animated pulse infinite" src="assets/img/DSC07366.jpg" alt="Sanitary Pad"/>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 className="text-primary mb-4">Natural Comfort <span className="fw-light text-dark">With Every Step</span></h1>
                <p className="mb-4">Our sanitary pads are crafted from banana stem pulp, providing an eco-friendly and sustainable solution without compromising on comfort. They are highly absorbent and gentle on sensitive skin.</p>
                <p className="mb-4">Experience nature’s softness with every step. Stay fresh and protected, knowing you're making a difference with biodegradable, hypoallergic pads that care for you and the planet</p>
                <a className="btn btn-primary py-2 px-4" href="/product">Shop Now</a>
            </div>
        </div>
    </div>
</div>

   
  
<div className="container-fluid py-5">
    <div className="container">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth:'600px'}}>
            <h1 className="text-primary mb-3">
                <span className="fw-light text-dark">Best Benefits Of Our</span> Premium Sanitary Pads
            </h1>
            <p className="mb-5">
                Experience the ultimate comfort and protection with our premium sanitary pads, designed to keep you feeling fresh and confident throughout your day.
            </p>
        </div>
        <div className="row g-4 align-items-center">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="row g-5">
                    <div className="col-12 d-flex">
                        <div className="btn-square rounded-circle border flex-shrink-0" style={{width:'80px' , height:'80px'}}>
                            <i className="fa fa-check fa-2x text-primary"></i>
                        </div>
                        <div className="ps-3">
                            <h5>Ultra-Absorbent</h5>
                            <hr className="w-25 bg-primary my-2"/>
                            <span>Our pads provide superior absorbency to keep you dry and comfortable all day.</span>
                        </div>
                    </div>
                    <div className="col-12 d-flex">
                        <div className="btn-square rounded-circle border flex-shrink-0" style={{width:'80px' , height:'80px'}}>
                            <i className="fa fa-check fa-2x text-primary"></i>
                        </div>
                        <div className="ps-3">
                            <h5>Breathable Material</h5>
                            <hr className="w-25 bg-primary my-2"/>
                            <span>Designed with breathable fabric to ensure comfort and prevent Rashes.</span>
                        </div>
                    </div>
                    <div className="col-12 d-flex">
                        <div className="btn-square rounded-circle border flex-shrink-0" style={{width:'80px' , height:'80px'}}>
                            <i className="fa fa-check fa-2x text-primary"></i>
                        </div>
                        <div className="ps-3">
                            <h5>Odor Control</h5>
                            <hr className="w-25 bg-primary my-2"/>
                            <span>Featuring advanced odor control technology for a fresh feeling all day long.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <img className="img-fluid animated pulse infinite" src="assets/img/feature.jpg" alt="Premium Sanitary Pads"/>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="row g-5">
                    <div className="col-12 d-flex">
                        <div className="btn-square rounded-circle border flex-shrink-0" style={{width:'80px' , height:'80px'}}>
                            <i className="fa fa-check fa-2x text-primary"></i>
                        </div>
                        <div className="ps-3">
                            <h5>Comfortable Fit</h5>
                            <hr className="w-25 bg-primary my-2"/>
                            <span>Designed for a perfect fit to prevent leaks and ensure maximum comfort.</span>
                        </div>
                    </div>
                    <div className="col-12 d-flex">
                        <div className="btn-square rounded-circle border flex-shrink-0" style={{width:'80px' , height:'80px'}}>
                            <i className="fa fa-check fa-2x text-primary"></i>
                        </div>
                        <div className="ps-3">
                            <h5>Hypoallergenic</h5>
                            <hr className="w-25 bg-primary my-2"/>
                            <span>Made from hypoallergenic materials to minimize skin sensitivity and irritation.</span>
                        </div>
                    </div>
                    <div className="col-12 d-flex">
                        <div className="btn-square rounded-circle border flex-shrink-0" style={{width:'80px' , height:'80px'}}>
                            <i className="fa fa-check fa-2x text-primary"></i>
                        </div>
                        <div className="ps-3">
                            <h5>Eco-Friendly</h5>
                            <hr className="w-25 bg-primary my-2"/>
                            <span>Our pads are designed with environmentally friendly materials to support sustainability.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

   
<div className="container-fluid how-to-use bg-primary my-5 py-5">
    <div className="container text-white py-5">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth:'600px'}}>
            <h1 className="text-white mb-3">
                <span className="fw-light text-dark">How To Use Our</span> Premium
                <span className="fw-light text-dark">Sanitary Pads</span>
            </h1>
            <p className="mb-5">
                Follow these simple steps for a comfortable and effective experience with our premium sanitary pads.
            </p>
        </div>
        <div className="row g-5">
            <div className="col-lg-4 text-center wow fadeIn" data-wow-delay="0.1s">
                <div className="btn-square rounded-circle border mx-auto mb-4" style={{width:'120px' , height:'120px'}}>
                    <i className="fa fa-bath fa-3x text-dark"></i>
                </div>
                <h5 className="text-white">Start with a Fresh Clean</h5>
                <hr className="w-25 bg-light my-2 mx-auto"/>
                <span>Ensure you start with clean and dry hands. Wash and dry your intimate area before applying the pad.</span>
            </div>
            <div className="col-lg-4 text-center wow fadeIn" data-wow-delay="0.3s">
                <div className="btn-square rounded-circle border mx-auto mb-4" style={{width:'120px' , height:'120px'}}>
                    <i className="fa fa-plus-square fa-3x text-dark"></i>
                </div>
                <h5 className="text-white">Apply Pad Securely</h5>
                <hr className="w-25 bg-light my-2 mx-auto"/>
                <span>Remove the backing from the pad and place it in the center of your Panty/Lingerie, ensuring it is secure and comfortable.</span>
            </div>
            <div className="col-lg-4 text-center wow fadeIn" data-wow-delay="0.5s">
                <div className="btn-square rounded-circle border mx-auto mb-4" style={{width:'120px' , height:'120px'}}>
                    <i className="fa fa-sync-alt fa-3x text-dark"></i>
                </div>
                <h5 className="text-white">Change Regularly</h5>
                <hr className="w-25 bg-light my-2 mx-auto"/>
                <span>Change your pad regularly throughout the day to maintain comfort and hygiene. Dispose of used pads properly.</span>
            </div>
        </div>
    </div>
</div>


<div className="container-fluid py-5">
    <div className="container">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
            <h1 className="text-primary mb-3"><span className="fw-light text-dark">Our Essential</span> Sanitary Products</h1>
            <p className="mb-5">Explore our range of panty liners, maternity pads, and sanitary pads, designed for comfort and reliability to meet your everyday needs.</p>
        </div>
        <div className="row g-4">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <div className="product-item text-center border h-100 p-4">
                    <img className="img-fluid mb-4" src="assets/img/pantyliner.jpg" alt="Panty Liner"/>
                    <div className="mb-2">
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small>(85)</small>
                    </div>
                    <a href="" className="h6 d-inline-block mb-2">Sanitary Napkins(8 Pads)</a>
                    <h5 className="text-primary mb-3">Light and Comfortable</h5>
                    <p>XL Size (320 mm long )</p>
                    <h5 class="text-primary mb-3">INR &#8377; 80/-</h5>
                    <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="product-item text-center border h-100 p-4">
                    <img className="img-fluid mb-4" src="assets/img/maternitypads.jpg" alt="Maternity Pad"/>
                    <div className="mb-2">
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small>(90)</small>
                    </div>
                    <a href="" className="h6 d-inline-block mb-2">Maternity Pads(5 pads)</a>
                    <h5 className="text-primary mb-3">Soft and Absorbent</h5>
                    <p>XXXL Size (40 &times;10 cm)</p>
                    <h5 class="text-primary mb-3">INR &#8377; 125/-</h5>
                    <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="product-item text-center border h-100 p-4">
                    <img className="img-fluid mb-4" src="assets/img/DSC07366.jpg" alt="Sanitary Pad"/>
                    <div className="mb-2">
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small className="fa fa-star text-primary"></small>
                        <small>(120)</small>
                    </div>
                    <a href="" className="h6 d-inline-block mb-2">Sanitary Pads(30 Pads)</a>
                    <h5 className="text-primary mb-3">Comfort and Protection</h5>
                    <p>XXL Size (340 mm long)</p>
                    <h5 class="text-primary mb-3">INR &#8377; 600/-</h5>

                    <a href="" className="btn btn-outline-primary px-3">Buy Now</a>
                </div>
            </div>
        </div>
    </div>
</div>

   
    <div className="container-fluid testimonial bg-primary my-5 py-5">
    <div className="container text-white py-5">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth:'600px'}}>
            <h1 className="text-white mb-3">
                Our Customers <span className="fw-light text-dark">Share Their Experience With Our Sanitary Pads</span>
            </h1>
            <p className="mb-5">
                Read what our satisfied customers have to say about the comfort and quality of our sanitary pads.
            </p>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-8">
                <div className="owl-carousel testimonial-carousel wow fadeIn" data-wow-delay="0.1s">
                    <div className="testimonial-item text-center" data-dot="1">
                        <img className="img-fluid border p-2" src="assets/img/testimonial-1.jpg" alt=""/>
                        <h5 className="fw-light lh-base text-white">
                            "I found these sanitary pads to be incredibly comfortable and absorbent. They have made my period days much more manageable and worry-free. Highly recommend!"
                        </h5>
                        <h5 className="mb-1">Jane Doe</h5>
                        <h6 className="fw-light text-white fst-italic mb-0">Teacher</h6>
                    </div>
                    <div className="testimonial-item text-center" data-dot="2">
                        <img className="img-fluid border p-2" src="assets/img/testimonial-2.jpg" alt=""/>
                        <h5 className="fw-light lh-base text-white">
                            "These pads are a game-changer! They provide excellent comfort and protection, and I love that they are made from natural materials."
                        </h5>
                        <h5 className="mb-1">Emily Smith</h5>
                        <h6 className="fw-light text-white fst-italic mb-0">Fitness Trainer</h6>
                    </div>
                    <div className="testimonial-item text-center" data-dot="3">
                        <img className="img-fluid border p-2" src="assets/img/testimonial-3.jpg" alt=""/>
                        <h5 className="fw-light lh-base text-white">
                            "I've tried many brands, but these sanitary pads are by far the most comfortable and reliable. They make me feel confident and secure throughout the day."
                        </h5>
                        <h5 className="mb-1">Sarah Johnson</h5>
                        <h6 className="fw-light text-white fst-italic mb-0">Nurse</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="container-fluid py-5">
      <div className="container">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
          <h1 className="text-primary mb-5">
            <span className="fw-light text-dark">Interested in Becoming a Distributor?</span> We Would Love to Hear From You
          </h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-7 wow fadeIn" data-wow-delay="0.1s">
            <p className="mb-4">
              Please fill out the form below with your details, and our team will get back to you as soon as possible. We appreciate your interest in partnering with us.
            </p>
            <div className="wow fadeIn" data-wow-delay="0.3s">
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="name" placeholder="Your Name" />
                      <label htmlFor="name">Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="email" className="form-control" id="email" placeholder="Your Email Address" />
                      <label htmlFor="email">Email Address</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="tel" className="form-control" id="contactNo" placeholder="Your Contact Number" />
                      <label htmlFor="contactNo">Contact No</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="experience" placeholder="Previous Experience" />
                      <label htmlFor="experience">Previous Experience</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control" id="referral" placeholder="How did you hear about us?" />
                      <label htmlFor="referral">How did you hear about us?</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100 py-3" type="submit">
                      Submit Form
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.5s">
          <img className="img-fluid animated pulse infinite" src="assets/img/hand-drawn-international-trade-with-truck_23-2149157911.avif" alt="Sanitary Pad"/>

          </div>
        </div>
      </div>
    </div>
</div>


  )
}

export default Home