import React from 'react';

const Contact = () => {
  return (
    <div>
      <div className="container-fluid bg-primary hero-header mb-5">
        <div className="container text-center">
          <h1 className="display-4 text-white mb-3 animated slideInDown">Contact Us</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">Contact Us</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="contact-info-item position-relative bg-primary text-center p-1">
                <div className="border py-5 px-3">
                  <i className="fa fa-map-marker-alt fa-3x text-dark mb-4"></i>
                  <h5 className="text-white">Office Address</h5>
                  <h5 className="fw-light text-white"> Om Niwas , Aadarsh nagar ,  Behind D - Mart , In front of Agasti apartment ,  Jalgaon, Pincode - 425001</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="contact-info-item position-relative bg-primary text-center p-3">
                <div className="border py-5 px-3">
                  <i className="fa fa-phone-alt fa-3x text-dark mb-4"></i>
                  <h5 className="text-white">Call Us</h5>
                  <h5 className="fw-light text-white">+91 92099 39693 ,<br/>+91 8788664860</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="contact-info-item position-relative bg-primary text-center p-3">
                <div className="border py-5 px-3">
                  <i className="fa fa-envelope fa-3x text-dark mb-4"></i>
                  <h5 className="text-white">Mail Us</h5>
                  <h5 className="fw-light text-white">aruhealsolutions@gmail.com</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
            <h1 className="text-primary mb-5">
              <span className="fw-light text-dark">Got Any Questions?</span> We're Here to Help
            </h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-7 wow fadeIn" data-wow-delay="0.1s">
              <p className="mb-4">
                Please fill out the contact form below and our team will get back to you as soon as possible. For immediate assistance, feel free to reach out to us through phone or email.
              </p>
              <div className="wow fadeIn" data-wow-delay="0.3s">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="name" placeholder="Your Name" />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="email" className="form-control" id="email" placeholder="Your Email" />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="subject" placeholder="Subject" />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ minHeight: '150px' }}></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.5s">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.099930842972!2d75.55854497471331!3d20.988630989156622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90f3f3211da93%3A0x979613e41939c5f6!2saruheal%20solutions!5e0!3m2!1sen!2sin!4v1726225527632!5m2!1sen!2sin" width="600" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
